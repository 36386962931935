import React, { useEffect } from 'react'
import '../resources/css/Home.css'

import portfolioCircle from '../resources/images/Portfolio-Circle-Two.png';
import portfolioLeftLogo from '../resources/images/Portfolio-left-logo.svg';
import portfolioRightLogo from '../resources/images/Portfolio-right-logo.svg';
import { Link } from 'react-router-dom';

import PortfolioImgOneImg from '../resources/images/Portfolio_1.jpg';
import PortfolioImgTwo from '../resources/images/Portfolio_2.jpg';
import PortfolioImgThree from '../resources/images/Portfolio_3.jpg';
import HomeBanner from './components/Banner/HomeBanner';
import LetsConnectForm from '../components/LetsConnectForm';
import SingleBar from '../components/SingleBar';

import homeAbout from '../resources/images/home_about_01.jpg';

const portfolioLeftLogoImg = { backgroundImage: `url(${portfolioLeftLogo})` };
const portfolioCircleImg = { backgroundImage: `url(${portfolioCircle})` };
const portfolioRightLogoImg = { backgroundImage: `url(${portfolioRightLogo})` };


const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HomeBanner />
            <section className='aboutText'>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-4 my-3" data-aos="fade-up">
                            <div className="OurPartnersLogoImgInner">
                                <img src={homeAbout} className='img-fluid' alt="About Cosmos" />
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-7 my-3" data-aos="fade-up">
                            <div className="sectionHeader lineLeft">
                                <h2>About Cosmos</h2>
                            </div>
                            <div className="aboutTextInner">
                                <p>Cosmos was formed in 2019 and is based in the Kingdom’s capital, Riyadh - and is part of the successful family-owned Lemal Holding Group. Cosmos operates a diverse portfolio of Art, Entertainment and Sports related businesses.</p>
                                <p>Cosmos specialises in the sourcing, partnering and acquisition of unique International IPs and brands exclusively for delivery into the Saudi market through Cosmos’s trusted established business relationships.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='OurPortfolio'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="100">
                            <div className="portfolio-thumb effect-3 Arts">
                                <div className="portfolio ov-left" style={portfolioLeftLogoImg}> </div>
                                <div className="effect-img">
                                    <img src={PortfolioImgOneImg} alt="mage" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="currentColor"><path d="M74.6 373.2c41.7 36.1 108 82.5 166.1 73.7c6.1-.9 12.1-2.5 18-4.5c-9.2-12.3-17.3-24.4-24.2-35.4c-21.9-35-28.8-75.2-25.9-113.6c-20.6 4.1-39.2 13-54.7 25.4c-6.5 5.2-16.3 1.3-14.8-7c6.4-33.5 33-60.9 68.2-66.3c2.6-.4 5.3-.7 7.9-.8l19.4-131.3c2-13.8 8-32.7 25-45.9C278.2 53.2 310.5 37 363.2 32.2c-.8-.7-1.6-1.4-2.4-2.1C340.6 14.5 288.4-11.5 175.7 5.6S20.5 63 5.7 83.9C0 91.9-.8 102 .6 111.8L24.8 276.1c5.5 37.3 21.5 72.6 49.8 97.2zm87.7-219.6c4.4-3.1 10.8-2 11.8 3.3c.1 .5 .2 1.1 .3 1.6c3.2 21.8-11.6 42-33.1 45.3s-41.5-11.8-44.7-33.5c-.1-.5-.1-1.1-.2-1.6c-.6-5.4 5.2-8.4 10.3-6.7c9 3 18.8 3.9 28.7 2.4s19.1-5.3 26.8-10.8zM261.6 390c29.4 46.9 79.5 110.9 137.6 119.7s124.5-37.5 166.1-73.7c28.3-24.5 44.3-59.8 49.8-97.2l24.2-164.3c1.4-9.8 .6-19.9-5.1-27.9c-14.8-20.9-57.3-61.2-170-78.3S299.4 77.2 279.2 92.8c-7.8 6-11.5 15.4-12.9 25.2L242.1 282.3c-5.5 37.3-.4 75.8 19.6 107.7zM404.5 235.3c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7c.1-.5 .1-1.1 .2-1.6c3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1 .5-.2 1.1-.3 1.6c-1 5.3-7.4 6.4-11.8 3.3zm136.2 15.5c-1 5.3-7.4 6.4-11.8 3.3c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7c.1-.5 .1-1.1 .2-1.6c3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1 .5-.2 1.1-.3 1.6zM530 350.2c-19.6 44.7-66.8 72.5-116.8 64.9s-87.1-48.2-93-96.7c-1-8.3 8.9-12.1 15.2-6.7c23.9 20.8 53.6 35.3 87 40.3s66.1 .1 94.9-12.8c7.6-3.4 16 3.2 12.6 10.9z" /></svg>
                                    </div>
                                    <div className="portfolioTet">
                                        <h2>Arts</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="200">
                            <div className="portfolio-thumb effect-3 Entertainment">
                                {/* <Link onClick={() => { toggleDrawer(anchor, false); scrollToTop(); }} to="/sports" > Sports </Link>  */}
                                <Link to="/entertainment">
                                    <div className="portfolio ov-img" style={portfolioCircleImg}> </div>
                                    <div className="effect-img">
                                        <img src={PortfolioImgTwo} alt="mage" />
                                    </div>
                                    <div className="portfolioOverlayBox effect-text">
                                        <div className="portfolioIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
                                        </div>
                                        <div className="portfolioTet">
                                            <h2>Entertainment</h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300">
                            <div className="portfolio-thumb effect-3 sports">
                                <Link to="/sports">
                                    <div className="portfolio ov-right" style={portfolioRightLogoImg}> </div>
                                    <div className="effect-img">
                                        <img src={PortfolioImgThree} alt="mage" />
                                    </div>
                                    <div className="portfolioOverlayBox effect-text">
                                        <div className="portfolioIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" /></svg>
                                        </div>
                                        <div className="portfolioTet">
                                            <h2>Sports</h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='cosmosServicesBox'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="cosmosServicesTop text-center" data-aos="fade-up" data-aos-delay="100">
                                <div className="sectionHeader skyeLine">
                                    <h2>Cosmos Services</h2>
                                </div>
                                <div className="cosmosServicesTopText" data-aos="fade-up" data-aos-delay="200">
                                    <p>We offer a broad range of services within each division to align with the sector's mandates and market demand.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cosmosServicesBoxInner">
                        <div className="row gx-5">
                            <div className="col-md-6 col-lg-4 my-3" data-aos="fade-up" data-aos-delay="300">
                                <div className="cosmosServicesBoxCard">
                                    <div className="cosmosServicesCard">
                                        <div className="ServicesCardIconText">
                                            <div className="ServicesCardIcon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="currentColor"><path d="M74.6 373.2c41.7 36.1 108 82.5 166.1 73.7c6.1-.9 12.1-2.5 18-4.5c-9.2-12.3-17.3-24.4-24.2-35.4c-21.9-35-28.8-75.2-25.9-113.6c-20.6 4.1-39.2 13-54.7 25.4c-6.5 5.2-16.3 1.3-14.8-7c6.4-33.5 33-60.9 68.2-66.3c2.6-.4 5.3-.7 7.9-.8l19.4-131.3c2-13.8 8-32.7 25-45.9C278.2 53.2 310.5 37 363.2 32.2c-.8-.7-1.6-1.4-2.4-2.1C340.6 14.5 288.4-11.5 175.7 5.6S20.5 63 5.7 83.9C0 91.9-.8 102 .6 111.8L24.8 276.1c5.5 37.3 21.5 72.6 49.8 97.2zm87.7-219.6c4.4-3.1 10.8-2 11.8 3.3c.1 .5 .2 1.1 .3 1.6c3.2 21.8-11.6 42-33.1 45.3s-41.5-11.8-44.7-33.5c-.1-.5-.1-1.1-.2-1.6c-.6-5.4 5.2-8.4 10.3-6.7c9 3 18.8 3.9 28.7 2.4s19.1-5.3 26.8-10.8zM261.6 390c29.4 46.9 79.5 110.9 137.6 119.7s124.5-37.5 166.1-73.7c28.3-24.5 44.3-59.8 49.8-97.2l24.2-164.3c1.4-9.8 .6-19.9-5.1-27.9c-14.8-20.9-57.3-61.2-170-78.3S299.4 77.2 279.2 92.8c-7.8 6-11.5 15.4-12.9 25.2L242.1 282.3c-5.5 37.3-.4 75.8 19.6 107.7zM404.5 235.3c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7c.1-.5 .1-1.1 .2-1.6c3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1 .5-.2 1.1-.3 1.6c-1 5.3-7.4 6.4-11.8 3.3zm136.2 15.5c-1 5.3-7.4 6.4-11.8 3.3c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7c.1-.5 .1-1.1 .2-1.6c3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1 .5-.2 1.1-.3 1.6zM530 350.2c-19.6 44.7-66.8 72.5-116.8 64.9s-87.1-48.2-93-96.7c-1-8.3 8.9-12.1 15.2-6.7c23.9 20.8 53.6 35.3 87 40.3s66.1 .1 94.9-12.8c7.6-3.4 16 3.2 12.6 10.9z" /></svg>
                                            </div>
                                            <div className="ServicesCardText">
                                                <h2>Arts</h2>
                                                <p>Cultivating and promoting local and international art within the Kingdom through traditional and new mediums to captivate and educate new audiences.</p>
                                                <Link title="Coming Soon" to="">
                                                    <span className='linkLine'></span>
                                                    <span className='linkText'>Coming soon</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 my-3" data-aos="fade-up" data-aos-delay="400">
                                <div className="cosmosServicesBoxCard">
                                    <div className="cosmosServicesCard">
                                        <div className="ServicesCardIconText">
                                            <div className="ServicesCardIcon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
                                            </div>
                                            <div className="ServicesCardText">
                                                <h2>Entertainment</h2>
                                                <p>Delivering a unique portfolio of exclusive world-class International events, exhibitions and experiences to the local Saudi market.</p>
                                                <Link to="/entertainment">
                                                    <span className='linkLine'></span>
                                                    <span className='linkText'>Find out more</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 my-3" data-aos="fade-up" data-aos-delay="500">
                                <div className="cosmosServicesBoxCard">
                                    <div className="cosmosServicesCard">
                                        <div className="ServicesCardIconText">
                                            <div className="ServicesCardIcon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" /></svg>
                                            </div>
                                            <div className="ServicesCardText">
                                                <h2>Sports</h2>
                                                <p>Engaging and partnering with the world’s best-in-class brands and international sporting events for execution and application in the Kingdom.</p>
                                                <Link to="/sports">
                                                    <span className='linkLine'></span>
                                                    <span className='linkText'>Find out more</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <OurTeamHome /> */}
            <SingleBar />
            <LetsConnectForm
                letsConnectText="If your product, service or event is unique, innovative and best-in-class, then please reach out to us using the contact form below, and one of our team members will get back to you shortly!"
                formLineCl="yellowLine"
            />
        </>
    )
}

export default Home
