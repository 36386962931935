import React, { useEffect } from 'react'
import logo from '../../../resources/images/cosmos_Logo-White.svg'
import { Link, useLocation } from 'react-router-dom'
import Sidebars from './Sidebars';

const Header = () => {
    let location = useLocation();
    const currentPath = location.pathname;

    const [activeSection, setActiveSection] = React.useState(['entertainmentBanner', 'sportBanner']);

    const scrollToSection = (id: any) => {
        const element = document.getElementById(id);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const sectionIds = [
                'entertainmentBanner',
                'entertainmentAboutUs',
                'entertainmentServices',
                'entertainmentPortfolio',
                'entertainmentClients',
                'entertainmentContactUs',
                'sportBanner',
                'sportAboutUs',
                'sportServices',
                'sportPortfolio',
                'sportContactUs',
            ];

            for (const id of sectionIds) {
                const element = document.getElementById(id);
                if (element) {
                    const rect = element.getBoundingClientRect();

                    // Check if the element is within 100px from the top
                    if (rect.top - 100 <= 0) {
                        setActiveSection([id]);
                    }
                }
            }
        };
        setActiveSection(['entertainmentBanner', 'sportBanner']);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header id="header" className='headerNav'>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-dark navbar-dark mb-3">
                <div className="container-fluid">
                    <Link className="navbar-brand me-auto" to={'/'}><img src={logo} alt="Cosmos" /></Link>
                    <div className="navbar-collapse collapse d-lg-inline-flex  flex-grow-0" id="navbarSupportedContent">
                        {
                            currentPath === '/' ?
                                <>
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item artsNavTop"><Link className={`nav-link ${location.pathname === "" ? "active" : ""}`} title="Coming Soon" to="#">Arts</Link></li>
                                        <li className="nav-item entertainmentNavTop"><Link className={`nav-link ${location.pathname === "/entertainment" ? "active" : ""}`} to="/entertainment">Entertainment</Link></li>
                                        <li className="nav-item sportsNavTop"><Link className={`nav-link ${location.pathname === "/sports" ? "active" : ""}`} to="/sports">Sports</Link></li>
                                    </ul>
                                </> : null}
                        {
                            currentPath === '/arts' ?
                                <>
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 artsNavTop">
                                        <li className="nav-item"><Link className={`nav-link ${location.pathname === "#artsBanner" ? "active" : ""}`} to={`${location.pathname}#artsBanner`} onClick={() => scrollToSection('artsBanner')}>Home</Link></li>
                                        <li className="nav-item"><Link className={`nav-link`} to={`${location.pathname}#artsAboutUs`} onClick={() => scrollToSection('artsAboutUs')}>About Us</Link></li>
                                        <li className="nav-item"><Link className={`nav-link`} to={`${location.pathname}#artsServices`} onClick={() => scrollToSection('artsServices')}>Services</Link></li>
                                        <li className="nav-item"><Link className={`nav-link`} to={`${location.pathname}#artsPortfolio`} onClick={() => scrollToSection('artsPortfolio')}>Portfolio</Link></li>
                                        <li className="nav-item"><Link className={`nav-link`} to={`${location.pathname}#artsClients`} onClick={() => scrollToSection('artsClients')}>Clients</Link></li>
                                        <li className="nav-item"><Link className={`nav-link`} to={`${location.pathname}#artsContactUs`} onClick={() => scrollToSection('artsContactUs')}>Contact Us</Link></li>
                                    </ul>
                                </>
                                : null
                        }
                        {
                            currentPath === '/entertainment' ?
                                <>
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 entertainmentNavTop">
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentBanner') ? 'active' : ''}`} to={`#entertainmentBanner`} onClick={() => scrollToSection('entertainmentBanner')}> Home </Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentAboutUs') ? 'active' : ''}`} to={`#entertainmentAboutUs`} onClick={() => scrollToSection('entertainmentAboutUs')} > About Us </Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentServices') ? 'active' : ''}`} to={`#entertainmentServices`} onClick={() => scrollToSection('entertainmentServices')}>Services</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentPortfolio') ? 'active' : ''}`} to={`#entertainmentPortfolio`} onClick={() => scrollToSection('entertainmentPortfolio')}>Portfolio</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentClients') ? 'active' : ''}`} to={`#entertainmentClients`} onClick={() => scrollToSection('entertainmentClients')}>Clients</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentContactUs') ? 'active' : ''}`} to={`#entertainmentContactUs`} onClick={() => scrollToSection('entertainmentContactUs')}>Contact Us</Link> </li>
                                    </ul>
                                </>
                                : null
                        }
                        {
                            currentPath === '/sports' ?
                                <>
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 sportsNavTop">
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportBanner') ? 'active' : ''}`} to={`#sportBanner`} onClick={() => scrollToSection('sportBanner')}>Home</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportAboutUs') ? 'active' : ''}`} to={`#sportAboutUs`} onClick={() => scrollToSection('sportAboutUs')}>About Us</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportServices') ? 'active' : ''}`} to={`#sportServices`} onClick={() => scrollToSection('sportServices')}>Services</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportPortfolio') ? 'active' : ''}`} to={`#sportPortfolio`} onClick={() => scrollToSection('sportPortfolio')}>Portfolio</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportContactUs') ? 'active' : ''}`} to={`#sportContactUs`} onClick={() => scrollToSection('sportContactUs')}>Contact Us</Link> </li>
                                    </ul>
                                </>
                                : null
                        }
                    </div>
                    <Sidebars />
                </div>
            </nav>
        </header >
    )
}
export default Header