import React, { useEffect } from 'react'
import OurPartners from '../components/OurPartners'
import '../resources/css/Arts.css'
import PortfolioImgOneImg from '../resources/images/Sports_Portfolio_1.jpg';
import PortfolioImgTwo from '../resources/images/Sports_Portfolio_2.jpg';
import PortfolioImgThree from '../resources/images/Sports_Portfolio_3.jpg';
import PortfolioImgFour from '../resources/images/Sports_Portfolio_4.jpg';
import PortfolioImgFive from '../resources/images/Sports_Portfolio_5.jpg';
import PortfolioImgSix from '../resources/images/Sports_Portfolio_6.jpg';

import InnerBannerSportsImg from '../resources/images/art-home-Cover.jpg';
import InnerBanner from './components/Banner/InnerBanner';
import ReactPlayer from 'react-player';
import LetsConnectForm from '../components/LetsConnectForm';
import SingleBar from '../components/SingleBar';

const artsVideoUrl = process.env.PUBLIC_URL + '/videos/Riyadh.mp4';

const Arts: React.FC = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div id="artsBanner">
                <InnerBanner
                    bannerTitle="Discover a New"
                    bannerTitleSubLine="Arts World"
                    bannerText=" "
                    bannerLinkText="Coming Soon"
                    bannerLink="https://www.google.com/"
                    bannerImg={InnerBannerSportsImg}
                    themeClass="artsBannerTop"
                    bannerPageName="arts"
                />
            </div>
            <section className='aboutText aboutUsArts' id="artsAboutUs">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-5 my-3" data-aos="fade-up">
                            <div className="OurPartnersLogoVideoInner">
                                <ReactPlayer
                                    className="videoPlayer"
                                    url={artsVideoUrl}
                                    controls={false}
                                    playing={true}
                                    width="100%"
                                    height="auto"
                                    loop={true}
                                    muted={true}
                                />
                            </div>
                        </div>
                        <div className="col-md-7 my-3" data-aos="fade-up">
                            <div className='sectionHeader whiteLine lineLeft'>
                                <h2>About Cosmos Arts</h2>
                            </div>
                            <div className="aboutTextInner">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim mollitia eos ducimus? Ipsam nobis aut laboriosam quidem optio dicta facere, doloremque expedita reprehenderit ratione odio sunt ad, praesentium aliquid. Totam!</p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim mollitia eos ducimus? Ipsam nobis aut laboriosam quidem optio dicta facere, doloremque expedita reprehenderit ratione odio sunt ad, praesentium aliquid. Totam!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="artsServices" id="artsServices">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="text-center">
                                <div className="sectionHeader yellowLine" data-aos="fade-up" data-aos-delay="100">
                                    <h2>Our Services</h2>
                                </div>
                                <p data-aos="fade-up" data-aos-delay="200">Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore laboriosam, incidunt tenetur.</p>
                            </div>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                    <div className="ServicesListArts">
                        <div className="row gx-5">
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="300">
                                <div className="ServicesListBoxArts oneLine">
                                    <h3>Lorem ipsum</h3>
                                    <ul>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="400">
                                <div className="ServicesListBoxArts twoLine">
                                    <h3>Lorem ipsum</h3>
                                    <ul>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="500">
                                <div className="ServicesListBoxArts threeLine">
                                    <h3>Lorem ipsum</h3>
                                    <ul>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="600">
                                <div className="ServicesListBoxArts fourLine">
                                    <h3>Lorem ipsum</h3>
                                    <ul>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                        <li>Lorem ipsum dolor</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='OurPortfolio' id="artsPortfolio">
                <div className="artsPage">
                    <div className="container">
                        <div className="col-md-8 mx-auto">
                            <div className="text-center">
                                <div className="sectionHeader yellowLine" data-aos="fade-up" data-aos-delay="100">
                                    <h2>Our Portfolio</h2>
                                </div>
                                {/* <p data-aos="fade-up" data-aos-delay="200">Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore laboriosam, incidunt tenetur.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300">
                            <div className="portfolio-thumb effect-3 Arts">
                                <div className="effect-img">
                                    <img src={PortfolioImgOneImg} alt="Soccer Supplements" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Arts</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="400">
                            <div className="portfolio-thumb effect-3 Arts">
                                <div className="effect-img">
                                    <img src={PortfolioImgTwo} alt="STATSports" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Arts</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 Arts">
                                <div className="effect-img">
                                    <img src={PortfolioImgThree} alt="The Player Care Group" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Arts</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300">
                            <div className="portfolio-thumb effect-3 Arts">
                                <div className="effect-img">
                                    <img src={PortfolioImgFour} alt="Cavendish Elite Sports Advisory" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Arts</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="400">
                            <div className="portfolio-thumb effect-3 Arts">
                                <div className="effect-img">
                                    <img src={PortfolioImgFive} alt="MundiCARDS" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Arts</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 Arts">
                                <div className="effect-img">
                                    <img src={PortfolioImgSix} alt="International Events" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Arts</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="artsClients">
                <OurPartners clientsLineCl="yellowLine" />
            </div>
            <SingleBar />
            <div id="artsContactUs">
                <LetsConnectForm
                    letsConnectText="If your product, service or event is unique, innovative and best-in-class, then please reach out to us using the contact form below, and one of our team members will get back to you shortly!"
                    formLineCl="yellowLine"
                />
            </div>
        </>
    )
}

export default Arts
