import React, { useEffect } from 'react'
import OurPartners from '../components/OurPartners'
import '../resources/css/Entertainment.css'

import EnPortfolioImgOneImg from '../resources/images/Entertainment_Portfolio_1.jpg';
import EnPortfolioImgTwo from '../resources/images/Entertainment_Portfolio_2.jpg';
import EnPortfolioImgThree from '../resources/images/Entertainment_Portfolio_3.jpg';
import EnPortfolioImgFour from '../resources/images/Entertainment_Portfolio_4.jpg';
import EnPortfolioImgFive from '../resources/images/Entertainment_Portfolio_5.jpg';
import EnPortfolioImgSix from '../resources/images/Entertainment_Portfolio_6.jpg';
import InnerBannerSportsImg from '../resources/images/Entertainment-Home-Cover.jpg';
import InnerBanner from './components/Banner/InnerBanner';

import LetsConnectForm from '../components/LetsConnectForm';
import SingleBar from '../components/SingleBar';

import entertainmentAbout from '../resources/images/entertainment_about_01.jpg';

const Entertainment: React.FC = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div id="entertainmentBanner">
                <InnerBanner
                    bannerTitle="Creating Memorable"
                    bannerTitleSubLine="Event Experiences"
                    bannerText=" "
                    bannerLinkText="BUY TICKETS ONLINE"
                    bannerLink="https://www.google.com/"
                    bannerImg={InnerBannerSportsImg}
                    themeClass="entertainmentBannerTop"
                    bannerPageName="entertainment"
                />
            </div>
            <section className='aboutText aboutUsEntertainment' id="entertainmentAboutUs">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-4 my-3" data-aos="fade-up">
                            <div className="OurPartnersLogoImgInner">
                                <img src={entertainmentAbout} className='img-fluid' alt="About Cosmos" />
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-7 my-3" data-aos="fade-up">
                            <div className="sectionHeader whiteLine lineLeft">
                                <h2>About Cosmos Entertainment</h2>
                            </div>
                            <div className="aboutTextInner">
                                <p>Cosmos Entertainment was formed in 2019 and is headquartered in Riyadh, Saudi Arabia. We specialise in acquiring exclusive and unique international Intellectual Properties (IPs) for entertainment, event and experience-related activities within the Kingdom of Saudi Arabia.</p>
                                <p>Our portfolio of activations consists of leading world-acclaimed immersive experiences, art activations, edutainment-related exhibitions, Michelin culinary events, and international acts such as US Magician ‘Criss Angel’, and other family theatrical entertainment shows.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sportSourServices' id="entertainmentServices">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="text-center">
                                <div className="sectionHeader skyeLine" data-aos="fade-up" data-aos-delay="100">
                                    <h2>Our Services</h2>
                                </div>
                                <p data-aos="fade-up" data-aos-delay="200">Cosmos Entertainment is experienced in partnering with International IPs for securing, delivering and executing the event in the Kingdom.</p>
                            </div>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                    <div className="ServicesListEn">
                        <div className="row gx-5">
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="300">
                                <div className="ServicesListBoxEn oneLine">
                                    <h3>Events</h3>
                                    <ul>
                                        <li>Acquire IP Rights Exclusively</li>
                                        <li>Secure Local Entity Interest</li>
                                        <li>360° Project Planning</li>
                                        <li>Arrange Local Permits/Licenses</li>
                                        <li>Local Project Management</li>
                                        <li>On-site Operations</li>
                                        <li>Marketing Strategies</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="400">
                                <div className="ServicesListBoxEn twoLine">
                                    <h3>Exhibitions</h3>
                                    <ul>
                                        <li>Acquire IP Rights Exclusively</li>
                                        <li>Secure Local Entity Interest</li>
                                        <li>360° Project Planning</li>
                                        <li>Arrange Local Permits/Licenses</li>
                                        <li>Local Project Management</li>
                                        <li>On-site Operations</li>
                                        <li>Marketing Strategies</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="500">
                                <div className="ServicesListBoxEn threeLine">
                                    <h3>Activations</h3>
                                    <ul>
                                        <li>Create New Concepts</li>
                                        <li>Develop Propositions</li>
                                        <li>Manage Execution & Logistics</li>
                                        <li>Handle Operations</li>
                                        <li>Marketing Strategies</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="600">
                                <div className="ServicesListBoxEn fourLine">
                                    <h3>Content Creation</h3>
                                    <ul>
                                        <li>Our creative team can create and produce new experiences utilising our International best-in-class partners and technologies with local content and talent.</li>
                                        <li>Creating unique, Saudi-themed cultural and pertinent concepts for entertainment activations locally and internationally.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='OurPortfolio' id="entertainmentPortfolio">
                <div className="entertainmentPage">
                    <div className="container">
                        <div className="col-md-8 mx-auto">
                            <div className="text-center">
                                <div className="sectionHeader skyeLine" data-aos="fade-up" data-aos-delay="100">
                                    <h2>Our Portfolio</h2>
                                </div>
                                {/* <p data-aos="fade-up" data-aos-delay="200">Cosmos Sports has partnered with the best-in-class in their respective market sectors.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300">
                            <div className="portfolio-thumb effect-3 Entertainment">
                                <div className="effect-img">
                                    <img src={EnPortfolioImgOneImg} alt="Criss Angel" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Criss Angel</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="400">
                            <div className="portfolio-thumb effect-3 Entertainment">
                                <div className="effect-img">
                                    <img src={EnPortfolioImgTwo} alt="Jeddah Jungle" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Jeddah Jungle</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 Entertainment">
                                <div className="effect-img">
                                    <img src={EnPortfolioImgThree} alt="The Player Care Group" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Imagine Picasso</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300">
                            <div className="portfolio-thumb effect-3 Entertainment">
                                <div className="effect-img">
                                    <img src={EnPortfolioImgFour} alt="Global Culinary Festival" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Global Culinary Festival</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="400">
                            <div className="portfolio-thumb effect-3 Entertainment">
                                <div className="effect-img">
                                    <img src={EnPortfolioImgFive} alt="MundiCARDS" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Fragrance Event</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 Entertainment">
                                <div className="effect-img">
                                    <img src={EnPortfolioImgSix} alt="International Events" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Immersive Event</h2>
                                        <p>Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="entertainmentClients">
                <OurPartners clientsLineCl="skyeLine" />
            </div>
            <SingleBar />
            <div id="entertainmentContactUs">
                <LetsConnectForm letsConnectText="If your IP, event or experience is unique, innovative and best-in-class, then please reach out to us using the contact form below, and one of our team members will get back to you shortly!" formLineCl="skyeLine" />
            </div>
        </>
    )
}

export default Entertainment
