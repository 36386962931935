import React, { useEffect } from 'react'
import '../resources/css/Sports.css'

import PortfolioImgOneImg from '../resources/images/Sports_Portfolio_1.jpg';
import PortfolioImgTwo from '../resources/images/Sports_Portfolio_2_02.jpeg';
import PortfolioImgThree from '../resources/images/Sports_Portfolio_3.jpg';
import PortfolioImgFour from '../resources/images/Sports_Portfolio_4.jpg';
import PortfolioImgFive from '../resources/images/Sports_Portfolio_5.jpg';
import PortfolioImgSix from '../resources/images/Sports_Portfolio_6.jpg';

import PortfolioImgSeven from '../resources/images/World-Downhill-Skateboarding-Championship.jpg';
import PortfolioImgEight from '../resources/images/Zenith-Coins.jpg';
import PortfolioImgNine from '../resources/images/World-Footvolley.jpg';

import InnerBannerSportsImg from '../resources/images/header-sports.jpg';
import InnerBanner from './components/Banner/InnerBanner';
import LetsConnectForm from '../components/LetsConnectForm';
import SingleBar from '../components/SingleBar';

import sportsAbout from '../resources/images/sports_about_01.jpg';

const Sports: React.FC = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div id="sportBanner">
                <InnerBanner
                    bannerTitle="Elevating Sports to"
                    bannerTitleSubLine="New Levels"
                    bannerText=" "
                    bannerLinkText="BUY TICKETS ONLINE"
                    bannerLink="https://www.google.com/"
                    bannerImg={InnerBannerSportsImg}
                    themeClass="sportsBannerTop"
                    bannerPageName="sports"
                />
            </div>
            <section className='aboutText aboutUsSports' id="sportAboutUs">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-4 my-3" data-aos="fade-up">
                            <div className="OurPartnersLogoImgInner">
                                <img src={sportsAbout} className='img-fluid' alt="About Cosmos" />
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-7 my-3" data-aos="fade-up">
                            <div className="sectionHeader whiteLine lineLeft">
                                <h2>About Cosmos Sports</h2>
                            </div>
                            <div className="aboutTextInner">
                                <p>We are a Saudi-based sports agency providing world-class sports services and events within the Kingdom of Saudi Arabia.</p>
                                <p>Cosmos Sports has established an elite team of world-renowned experts and exclusive partners for the Saudi sports market.</p>
                                <p>Our team of experts and partners have worked at the highest level with international pro sports clubs, players, national leagues, and sporting governing bodies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sportSourServices' id="sportServices">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="text-center">
                                <div className="sectionHeader cherryLine" data-aos="fade-up" data-aos-delay="100">
                                    <h2>Our Services</h2>
                                </div>
                                <p data-aos="fade-up" data-aos-delay="200">Cosmos Sports' primary focus is on 4 key areas of interest within the Saudi sports sector.</p>
                            </div>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                    <div className="ServicesList">
                        <div className="row gx-5">
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="300">
                                <div className="ServicesListBox oneLine">
                                    <h3>Saudi Football</h3>
                                    <ul>
                                        <li>Data Analysis & Tracking</li>
                                        <li>Fan Engagement & Media</li>
                                        <li>Elite Medical Services</li>
                                        <li>Player Care</li>
                                        <li>Sports Science</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="400">
                                <div className="ServicesListBox twoLine">
                                    <h3>International Events</h3>
                                    <ul>
                                        <li>World Championships</li>
                                        <li>Federated Events</li>
                                        <li>New Sports Events</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="500">
                                <div className="ServicesListBox threeLine">
                                    <h3>Sponsorship</h3>
                                    <ul>
                                        <li>Intl. Football Clubs</li>
                                        <li>Intl. Sports Clubs</li>
                                        <li>Intl. Sports Events</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay="600">
                                <div className="ServicesListBox fourLine">
                                    <h3>Investment</h3>
                                    <ul>
                                        <li>New Sports Event IPs</li>
                                        <li>Existing Sports Event IPs</li>
                                        <li>Innovative Sports Technologies</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='OurPortfolio' id="sportPortfolio">
                <div className="sportsPage">
                    <div className="container">
                        <div className="col-md-8 mx-auto">
                            <div className="text-center">
                                <div className="sectionHeader cherryLine" data-aos="fade-up" data-aos-delay="100">
                                    <h2>Our Portfolio</h2>
                                </div>
                                {/* <p data-aos="fade-up" data-aos-delay="200">Cosmos Sports has partnered with the best-in-class in their respective market sectors.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgOneImg} alt="Soccer Supplement" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Soccer Supplement</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="400">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgTwo} alt="eSkootr Championship" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2><span className='text-lowercase'>e</span>Skootr Championship</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgThree} alt="The Player Care Group" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>The Player Care Group</h2>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgSeven} alt="World Downhill Skateboarding Championship" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>World Downhill Skateboarding Championship</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgEight} alt="Zenith Coins" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Zenith Coins</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgNine} alt="World Footvolley" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>World Footvolley</h2>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgFour} alt="Cavendish Elite Sports Advisory" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Cavendish Elite Sports Advisory</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgFive} alt="Symbol Stars" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>Symbol Stars</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="500">
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={PortfolioImgSix} alt="International Events" />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>International Events</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <SingleBar />
            <div id="sportContactUs">
                <LetsConnectForm
                    letsConnectText="If your product, service or event is unique, innovative and best-in-class, then please reach out to us using the contact form below, and one of our team members will get back to you shortly!"
                    formLineCl="cherryLine" />
            </div>
        </>
    )
}

export default Sports
